import { forwardRef, useCallback, useEffect, useMemo } from "react";
import { useForm, zodResolver } from "@mantine/form";
import { z } from "zod";
import { useTranslation } from "next-i18next";

import {
    StyledForm,
    StyledSecondRow,
    StyledInputItem,
    StyledFirstRow,
    StyledContent,
    StyledThirdRow,
} from "./create-user-form.styles";
import { useCreateUserMutation } from "lib/api/hooks/use-create-user-mutation.hook";
import {
    FORBID_SPECIAL_CHARACTERS_REGEX,
    FORBID_START_END_2_SPACES_REGEX,
} from "lib/constants/regex";
import { useGetRolesQuery } from "lib/api/hooks/use-get-roles-query.hook";
import { Dropdown } from "components/atoms/dropdown/dropdown.component";
import { V1CreateUserRequest } from "lib/api/common/data-contracts";
import {
    CreateUserFormProps,
    testIds,
    MANDATORY_2FA_LABEL_KEY,
    NOT_UNIQUE_EMAIL_KEY,
    INVALID_EMAIL_KEY,
    INVALID_FIRST_NAME_KEY,
    INVALID_LAST_NAME_KEY,
    ROLE_IS_REQUIRED_KEY,
    FORBID_START_END_2_SPACES_KEY,
    FIRST_NAME_LIMIT_KEY,
    LAST_NAME_LIMIT_KEY,
} from "./create-user-form.interface";
import { HiddenSubmit } from "components/atoms/hidden-submit/hidden-submit.component";
import { InputField } from "components/molecules/input-field/input-field.component";
import { Checkbox } from "components/atoms/checkbox/checkbox.component";
import { Text } from "components/atoms/text/text.component";
import { colors } from "lib/palette";
import {
    NOT_ASSIGNED_SUB_ROLE_OPTION,
    getSubRoleIdOrUndefined,
} from "lib/constants/not-assigned-sub-role";
import { ApiErrorCode } from "lib/error-handler";
import { ROOT_SUB_ROLES } from "lib/constants/root-sub-roles";

export const CreateUserForm = forwardRef<HTMLInputElement, CreateUserFormProps>(
    (
        {
            setFormState,
            onError,
            onSuccess,
            companyId,
            providerId,
            withSubRole,
            userType,
        },
        ref,
    ) => {
        const { t } = useTranslation("common");
        const { data: roles } = useGetRolesQuery({
            enabled: !!withSubRole,
        });

        const { mutate, isLoading } = useCreateUserMutation({
            onError: ({ error }) => {
                if (error?.code === ApiErrorCode.USER_ALREADY_EXISTS) {
                    setFieldError(
                        "email",
                        t(
                            NOT_UNIQUE_EMAIL_KEY,
                            "Email must be unique. User with specified email exists.",
                        ),
                    );
                } else {
                    onError(error);
                }
            },
            onSuccess,
        });

        const mappedRoles = useMemo(
            () =>
                [NOT_ASSIGNED_SUB_ROLE_OPTION].concat(
                    roles?.map((item) => ({
                        value: item.id,
                        label: item.title,
                        "data-testid": `${testIds.roleDropdownOption}-${item.id}`,
                    })) || [],
                ),
            [roles],
        );

        const schema = z.object({
            email: z
                .string()
                .email({ message: t(INVALID_EMAIL_KEY, "Email is invalid.") }),
            first_name: z
                .string()
                .regex(
                    FORBID_SPECIAL_CHARACTERS_REGEX,
                    t(
                        INVALID_FIRST_NAME_KEY,
                        "First name must contain only numbers, letters and spaces.",
                    ),
                )
                .regex(
                    FORBID_START_END_2_SPACES_REGEX,
                    t(
                        FORBID_START_END_2_SPACES_KEY,
                        "Space before and after text and double spaces are not supported.",
                    ),
                )
                .max(
                    255,
                    t(
                        FIRST_NAME_LIMIT_KEY,
                        "First name must be greater than 1 and less than 255 symbols.",
                    ),
                ),
            last_name: z
                .string()
                .regex(
                    FORBID_SPECIAL_CHARACTERS_REGEX,
                    t(
                        INVALID_LAST_NAME_KEY,
                        "Last name must contain only numbers, letters and spaces.",
                    ),
                )
                .regex(
                    FORBID_START_END_2_SPACES_REGEX,
                    t(
                        FORBID_START_END_2_SPACES_KEY,
                        "Space before and after text and double spaces are not supported.",
                    ),
                )
                .max(
                    255,
                    t(
                        LAST_NAME_LIMIT_KEY,
                        "Last name must be greater than 1 and less than 255 symbols.",
                    ),
                ),
            ...(withSubRole
                ? {
                      sub_role_id: z.string({
                          required_error: t(
                              ROLE_IS_REQUIRED_KEY,
                              "Role is required.",
                          ),
                      }),
                  }
                : {}),
        });

        const {
            setFieldValue,
            getInputProps,
            setFieldError,
            onSubmit,
            errors,
            isDirty,
        } = useForm<V1CreateUserRequest>({
            validate: zodResolver(schema),
            initialValues: {
                email: "",
                first_name: "",
                last_name: "",
                role: "",
                is_2fa_mandatory: true,
            },
        });

        const onSubmitHandler = async (values: V1CreateUserRequest) => {
            await mutate({
                ...values,
                role: userType,
                sub_role_id: withSubRole
                    ? getSubRoleIdOrUndefined(values?.sub_role_id)
                    : ROOT_SUB_ROLES[userType],
                ...(companyId ? { b2b_company_id: companyId } : {}),
                ...(providerId ? { vendor_id: providerId } : {}),
            });
        };

        const setTypeValue = useCallback(
            (data: string | null) => data && setFieldValue("sub_role_id", data),
            [setFieldValue],
        );

        useEffect(() => {
            setFormState({
                isLoading,
                isReady: isDirty(),
            });
        }, [isLoading, setFormState, isDirty]);

        return (
            <StyledContent data-testid={testIds.createUserFormComponent}>
                <StyledForm onSubmit={onSubmit(onSubmitHandler)}>
                    <StyledFirstRow withSubRole={withSubRole}>
                        <InputField
                            data-testid={testIds.createUserFormEmailInput}
                            label={t("createUserForm.label.email", "E-mail")}
                            required
                            errorMessage={errors.email}
                            name="email"
                            {...getInputProps("email")}
                        />
                        {withSubRole && (
                            <StyledInputItem
                                data-testid={testIds.createUserFormRoleInput}
                            >
                                <label>
                                    {t("createUserForm.label.role", "Role")}{" "}
                                    <span>*</span>
                                </label>
                                <Dropdown
                                    error={!!errors?.sub_role_id}
                                    errorMessage={errors?.sub_role_id as string}
                                    placeholder={t(
                                        "createUserForm.placeholder.role",
                                        "Select role",
                                    )}
                                    data={mappedRoles}
                                    onChange={setTypeValue}
                                    data-testid={
                                        testIds.createUserFormRoleDropdown
                                    }
                                />
                            </StyledInputItem>
                        )}
                    </StyledFirstRow>

                    <StyledSecondRow>
                        <InputField
                            label={t(
                                "createUserForm.label.firstName",
                                "First Name",
                            )}
                            errorMessage={errors.first_name}
                            name="first_name"
                            data-testid={testIds.createUserFormFirstNameInput}
                            {...getInputProps("first_name")}
                        />
                        <InputField
                            label={t(
                                "createUserForm.label.lastName",
                                "Last Name",
                            )}
                            errorMessage={errors.last_name}
                            name="last_name"
                            data-testid={testIds.createUserFormLastNameInput}
                            {...getInputProps("last_name")}
                        />
                    </StyledSecondRow>
                    <StyledThirdRow>
                        <Checkbox
                            data-testid={testIds.mandatory2faCheckbox}
                            {...getInputProps("is_2fa_mandatory", {
                                type: "checkbox",
                            })}
                        />
                        <Text color={colors.black_6} size={12}>
                            {t(
                                MANDATORY_2FA_LABEL_KEY,
                                "Two-factor authentication (2FA). If disable feature the user needs only email and password when log in. Disabling 2FA increases account vulnerability.",
                            )}
                        </Text>
                    </StyledThirdRow>
                    <HiddenSubmit
                        ref={ref}
                        data-testid={testIds.createUserFormHiddenSubmitButton}
                    />
                </StyledForm>
            </StyledContent>
        );
    },
);

CreateUserForm.displayName = "CreateUserForm";
