import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { UsersActionsBlock } from "components/entities/users/organisms/users-actions-block/users-actions-block.component";
import { Users } from "components/entities/users/organisms/users/users.component";
import { DefaultLayout } from "../default-layout/default-layout.component";
import { DeleteNotConfirmedUserModal } from "components/templates/users/components/delete-not-confirmed-user-modal/delete-not-confirmed-user-modal.component";
import { UserStatusWarningModal } from "./components/user-status-warning-modal/user-status-warning-modal.component";
import { ChangeUserRoleModal } from "components/entities/users/organisms/change-user-role-modal/change-user-role-modal.component";
import { useUserTemplate } from "./use-user-template.hook";
import { CreateNewUserModal } from "components/entities/users/organisms/create-new-user-modal/create-new-user-modal.component";
import { CreateNewUserModal as CreateNewProviderUser } from "components/entities/providers/create-new-user-modal/create-new-user-modal.component";
import { Content } from "components/templates/default-layout/components/content/content.component";
import {
    StyledFilterMobileButton,
    StyledSearchInputBlock,
    StyledTabletHeaderContent,
    StyledTitle,
    StyledUsersActionsLeftBlock,
    StyledUsersActionsRightBlock,
} from "./users.styled";
import { SearchInput } from "components/molecules/search-input/search-input.component";
import { Button } from "components/atoms/button/button.component";
import { UsersFilter } from "components/entities/users/organisms/users-filter/users-filter.component";
import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { UserInfoModal } from "components/entities/users/organisms/user-info-modal/user-info-modal.component";
import { Configure2FAModal } from "components/entities/users/organisms/configure-2FA-modal/configure-2FA-modal.component";
import { TabletContentHeader } from "../default-layout/components/tablet-content-header/tablet-content-header.component";
import { FallbackQuery } from "components/organisms/fallback-query/fallback-query.component";
import { UserType } from "lib/constants/user-type";
import { FALLBACK_ERROR_MESSAGE } from "./users.interface";

export const UsersTemplate = () => {
    const { t } = useTranslation("users");

    const {
        rightModalActiveStatus,
        close,
        open,
        setFilterActiveData,
        filterActiveData,
        userId,
        isUserInfoModalOpen,
        setIsUserInfoModalOpen,
        deleteUserInfo,
        setDeleteUserInfo,
        isNewUserModalOpen,
        setIsNewUserModalOpen,
        searchInputValue,
        setSearchInputValue,
        changeRoleData,
        setChangeRoleData,
        userStatusModalData,
        setUserStatusModalData,
        reset2FAModalData,
        setReset2FAModalData,
        usersList,
        setSortState,
        sortState,
        usersQuery,
        rolesQuery,
        userType,
    } = useUserTemplate();

    const Filter = useMemo(() => {
        return (
            <UsersFilter
                onCloseFilter={close}
                onChange={setFilterActiveData}
                value={filterActiveData}
            />
        );
    }, [filterActiveData, close, setFilterActiveData]);

    return (
        <DefaultLayout
            pageTitle={t("title.users", "Users")}
            rightModalIsOpen={rightModalActiveStatus}
            rightModalOnClose={close}
            rightModalContent={Filter}
            showHeaderShadowOnTablet={false}
        >
            <TabletContentHeader>
                <StyledTabletHeaderContent>
                    <StyledUsersActionsLeftBlock>
                        <StyledTitle>{t("title.users", "Users")}</StyledTitle>
                        <Button
                            showTitle
                            width={115}
                            onClick={() => setIsNewUserModalOpen(true)}
                        >
                            {t("button.createUser", "Create user")}
                        </Button>
                        <StyledSearchInputBlock>
                            <SearchInput
                                value={searchInputValue}
                                placeholder={t(
                                    "placeholder.searchUser",
                                    "Search user",
                                )}
                                setValue={setSearchInputValue}
                            />
                        </StyledSearchInputBlock>
                    </StyledUsersActionsLeftBlock>
                    <StyledUsersActionsRightBlock>
                        <StyledFilterMobileButton onClick={open}>
                            <Icon name={Icons.filterV2Svg} />
                        </StyledFilterMobileButton>
                    </StyledUsersActionsRightBlock>
                </StyledTabletHeaderContent>
            </TabletContentHeader>

            <Content withMobileHeader>
                <FallbackQuery
                    errorMessage={FALLBACK_ERROR_MESSAGE}
                    queries={[usersQuery, rolesQuery]}
                >
                    <UsersActionsBlock
                        openNewUserModal={() => setIsNewUserModalOpen(true)}
                        searchInputValue={searchInputValue}
                        setSearchInputValue={setSearchInputValue}
                    />
                    <Users
                        usersList={usersList}
                        sortBy={sortState}
                        setSortBy={setSortState}
                    />
                </FallbackQuery>
            </Content>

            {userType === UserType.PROVIDER ? (
                <CreateNewProviderUser
                    opened={isNewUserModalOpen}
                    onClose={() => setIsNewUserModalOpen(false)}
                />
            ) : (
                <CreateNewUserModal
                    opened={isNewUserModalOpen}
                    onClose={() => setIsNewUserModalOpen(false)}
                />
            )}

            <UserInfoModal
                opened={isUserInfoModalOpen}
                onClose={() => setIsUserInfoModalOpen(false)}
                userId={userId}
            />
            <DeleteNotConfirmedUserModal
                opened={!!deleteUserInfo}
                onClose={() => setDeleteUserInfo(undefined)}
                userInfo={deleteUserInfo}
            />
            <ChangeUserRoleModal
                opened={!!changeRoleData}
                onClose={() => setChangeRoleData(undefined)}
                user={changeRoleData}
            />
            <UserStatusWarningModal
                isOpen={userStatusModalData.isOpen}
                close={() =>
                    setUserStatusModalData((prev) => ({
                        ...prev,
                        isOpen: false,
                    }))
                }
                userData={userStatusModalData}
            />
            <Configure2FAModal
                onClose={() => setReset2FAModalData("")}
                opened={!!reset2FAModalData}
                userId={reset2FAModalData}
            />
        </DefaultLayout>
    );
};
