import { PropsWithChildren } from "react";

import { StyledFooter } from "./footer.styled";

export const Footer = (
    {
        children,
    }: PropsWithChildren
) => {
    return <StyledFooter>{children}</StyledFooter>;
};
