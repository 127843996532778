export interface FilterLayoutProps {
    onCloseFilter: () => void;
    onSubmit: () => void;
    onReset: () => void;
    totalActiveFilters: number;
    isDirty: boolean;
    testId?: string;
}

export enum DataTestIds {
    APPLY_FILTERS_BUTTON = "apply-filters-button",
    RESET_FILTER_BUTTON = "reset-filters-button",
    CLOSE_BUTTON = "close-button",
}
