import { ToastContent, ToastOptions } from "react-toastify";

import { HttpErrorType } from "lib/api/types";
import { ApiErrorCode } from "lib/error-handler";

import { errorToast, warningToast } from "./toast";

type ToastsConfig = {
    error?: {
        content?: ToastContent;
        config?: ToastOptions;
    };
    warning?: {
        content?: ToastContent;
        config?: ToastOptions;
    };
    content?: ToastContent; // common for error and warning
    config?: ToastOptions; // common for error and warning
};

export const showErrorToast = (
    error?: HttpErrorType,
    config?: ToastsConfig,
) => {
    if (error?.error?.code === ApiErrorCode.PERMISSION_DENIED) {
        warningToast(config?.warning?.content || config?.content, {
            ...(config?.config || {}),
            ...(config?.warning?.config || {}),
        });
    } else {
        errorToast(
            config?.error?.content || config?.content,
            {
                ...(config?.config || {}),
                ...(config?.error?.config || {}),
            }
        );
    }
};
