import { UserData } from "components/templates/users/users.interface";

export interface ChangeUserRoleModalProps {
    user?: UserData;
    opened: boolean;
    onClose: () => void;
}

export const testIds = {
    assignButton: "assign-button",
    cancelButton: "cancel-button",
    changeUserRoleModal: "change-user-role-modal",
    changeRoleErrorToast: "change-role-error-toast",
};

export const TOAST_SUCCESS_MESSAGE = "Role was changed.";
export const TOAST_SUCCESS_MESSAGE_KEY = "toast.successRoleChange";
export const TOAST_ERROR_MESSAGE = "There was an error. Role was not changed.";
export const TOAST_ERROR_MESSAGE_KEY = "toast.errorRoleChange";
