import { useRouter } from "next/router";
import { useRecoilValue } from "recoil";
import { useTranslation } from "next-i18next";

import { Menu } from "components/atoms/menu/menu.component";
import { userState } from "lib/recoil/atoms/user.atom";
import {
    REGISTRATION_LINK_ERROR_TOAST_MESSAGE_KEY,
    REGISTRATION_LINK_SUCCESS_TOAST_MESSAGE_KEY,
    UserInfoMenuButtonProps,
} from "./user-info-menu-button.interface";
import { getUsername } from "lib/utils/entities/users/get-username.utilit";
import { useResendTokenMutation } from "lib/api/hooks/use-resend-token-mutation";
import { paths } from "lib/constants/paths";
import { errorToast, successToast } from "lib/utils/toast";
import { useCheckUserPermissions } from "lib/hooks/use-check-user-permissions.hook";
import { Permission } from "lib/constants/permissions";
import { ActionButtonWrapper } from "components/atoms/menu/menu.styled";
import { UserType } from "lib/constants/user-type";

export const UserInfoMenuButton = ({
    userInfo,
    openUserInfoModal,
    setUserId,
    setDeleteUserInfo,
    setChangeRoleData,
    setUserStatusModalData,
    setReset2FAModalData,
}: UserInfoMenuButtonProps) => {
    const { t } = useTranslation("users");

    const router = useRouter();
    const { checkSome } = useCheckUserPermissions();

    const user = useRecoilValue(userState);
    const handleShowUserInfo = () => {
        setUserId(userInfo.id);
        openUserInfoModal(true);
    };

    const handleRedirectToUserAccessesPage = async () => {
        await router.push({
            pathname: paths.USERS_PERMISSIONS,
            query: { userId: userInfo?.id },
        });
    };

    const mutation = useResendTokenMutation({
        onSuccess: () => {
            successToast(
                t(
                    REGISTRATION_LINK_SUCCESS_TOAST_MESSAGE_KEY,
                    "Registration link was sent to the email",
                ),
            );
        },
        onError: () => {
            errorToast(
                t(
                    REGISTRATION_LINK_ERROR_TOAST_MESSAGE_KEY,
                    "There was an error. Registration link was not sent.",
                ),
            );
        },
    });

    const openStatusManagerModal = () => {
        setUserStatusModalData({
            id: userInfo.id,
            isBaned: !!userInfo?.is_banned,
            username: getUsername(userInfo),
            isOpen: true,
        });
    };

    const openDeleteModal = () => {
        setDeleteUserInfo({
            id: userInfo.id,
            username: getUsername(userInfo),
        });
    };

    const isCurrentUser = user?.auth?.user?.id === userInfo.id;

    const menuItems = [
        !userInfo?.is_confirmed && {
            children: t("button.sendInvitations", "Send the invitations again"),
            onClick: () => mutation.mutate(userInfo.id),
            testId: "send-invitations-again",
        },
        !isCurrentUser && {
            children: t("button.changeRole", "Change role"),
            onClick: () => setChangeRoleData(userInfo),
            testId: "change-role",
        },
        userInfo?.is_confirmed &&
            !isCurrentUser && {
                children: userInfo.is_banned
                    ? t("button.activate", "Activate")
                    : t("button.deactivate", "Deactivate"),
                onClick: openStatusManagerModal,
            },
        !isCurrentUser &&
            user?.auth.user.role !== UserType.PROVIDER && {
                children: t("button.setUpAccesses", "Set up accesses"),
                onClick: handleRedirectToUserAccessesPage,
                testId: "set-up-access",
            },
        !userInfo?.is_confirmed && {
            children: t("button.delete", "Delete"),
            onClick: openDeleteModal,
            testId: "delete",
        },
        {
            children: t("button.userInfo", "User info"),
            onClick: handleShowUserInfo,
            testId: "user-info",
        },
        userInfo?.is_confirmed &&
            checkSome([
                Permission.RESET_2FA,
                Permission.ENABLE_DISABLE_2FA,
            ]) && {
                children: t("button.configure2FA", "Configure 2FA"),
                onClick: () => setReset2FAModalData(userInfo.id),
            },
    ];

    return (
        <ActionButtonWrapper>
            <Menu offset={0} menuItems={menuItems.filter((i) => i) as []} />
        </ActionButtonWrapper>
    );
};
