// if date is a number you need pass seconds
export const generateDate = (date?: number | string, haveTime?: boolean) => {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    if (date) {
        const dateInfo =
            typeof date === "number" ? new Date(date * 1000) : new Date(date);
        const day =
            dateInfo.getDate() < 10
                ? `0${dateInfo.getDate()}`
                : dateInfo.getDate();
        const month = months[dateInfo.getMonth()];
        const year = dateInfo.getFullYear();
        const hours = dateInfo.getHours();
        const minutes = dateInfo.getMinutes();

        const time = () => {
            if (haveTime) {
                return `| ${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
            }
            return "";
        };

        if (haveTime) {
            return `${day} ${month} ${year} ${time()}`;
        }

        return `${day} ${month} ${year}`;
    }

    return "";
};
