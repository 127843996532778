import { ReactNode } from "react";

export type TableProps = {
    data?: Record<
        string,
        {
            name: string;
            render: () => ReactNode;
            id?: string;
        }
    >[];
    columns?: { name: string; render: () => ReactNode }[];
    onRowClick?: (
        data: Record<
            string,
            {
                name: string;
                render: () => ReactNode;
            }
        >,
    ) => void;
    showHeadInMobile?: boolean;
    activeRowId?: string | null;
    fallbackMessage?: string;
    isLoading?: boolean;
    dataTestId?: string;
};

export type StyledRowProps = {
    isClickable?: boolean;
    activeRowId?: string | null;
    rowId?: string;
};

export type StyledTheadProps = {
    showHeadInMobile?: boolean;
};

export type RowProps = {
    isHaveActions?: boolean;
};

export const TABLE_NO_DATA_KEY = "table.label.noData";
