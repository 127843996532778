import type { NextPage } from "next";

import { UsersTemplate } from "components/templates/users/users.template";
import { withTranslations } from "lib/utils/with-static-translation";

const Users: NextPage = () => {
    return <UsersTemplate />;
};

export const getStaticProps = withTranslations({
    namespaces: ["users", "common"],
});

export default Users;
