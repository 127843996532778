import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { Icon } from "components/molecules/icon/icon.component";
import { Icons } from "components/molecules/icon/icon.interface";
import { TABLE_NO_DATA_KEY, TableProps } from "./table.interface";
import {
    StyledTable,
    StyledTbody,
    StyledTd,
    StyledTh,
    StyledThead,
    StyledTr,
    StyledMessage,
    StyledLoaderBlock,
} from "./table.styled";
import { Spinner } from "components/atoms/spinner/spinner.component";

export const Table = ({
    data = [],
    columns = [],
    onRowClick,
    showHeadInMobile = true,
    activeRowId,
    fallbackMessage,
    isLoading,
    dataTestId,
}: TableProps) => {
    const { t } = useTranslation("common");

    const isHaveActions = useMemo(() => {
        if (data.length) {
            return data[0]?.actions !== undefined;
        }
    }, [data]);

    return (
        <>
            <StyledTable data-testid={dataTestId}>
                <StyledThead showHeadInMobile={showHeadInMobile}>
                    <StyledTr>
                        {columns.map((column, index) => (
                            <StyledTh
                                isHaveActions={isHaveActions}
                                className={
                                    column.name === "actions"
                                        ? "right-panel-head"
                                        : ""
                                }
                                key={index}
                            >
                                {column.render()}
                            </StyledTh>
                        ))}
                    </StyledTr>
                </StyledThead>
                {!isLoading && (
                    <StyledTbody>
                        {data.map((row, index) => (
                            <StyledTr
                                data-testid={row?.id?.render()}
                                isClickable={!!onRowClick}
                                onClick={() => {
                                    if (row && onRowClick) {
                                        onRowClick(row);
                                    }
                                }}
                                key={index}
                                rowId={row.data?.id}
                                activeRowId={activeRowId}
                            >
                                {columns.map((column, colIndex) => (
                                    <StyledTd
                                        isHaveActions={isHaveActions}
                                        className={
                                            column.name === "actions"
                                                ? "right-panel-row"
                                                : ""
                                        }
                                        key={`col-${colIndex}`}
                                    >
                                        {row[column.name].render()}
                                    </StyledTd>
                                ))}
                            </StyledTr>
                        ))}
                    </StyledTbody>
                )}
            </StyledTable>
            {!data.length && !isLoading && (
                <StyledMessage align="center">
                    {fallbackMessage || t(TABLE_NO_DATA_KEY, "No data")}
                </StyledMessage>
            )}
            {isLoading && (
                <StyledLoaderBlock>
                    <Spinner>
                        <Icon name={Icons.loaderSvg} />
                    </Spinner>
                </StyledLoaderBlock>
            )}
        </>
    );
};
