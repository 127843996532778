import { useTranslation } from "next-i18next";

import { Avatar } from "components/atoms/avatar/avatar.component";
import { UserStatusLabel } from "components/entities/users/molecules/user-status-label/user-status-label.component";
import { FallbackQuery } from "components/organisms/fallback-query/fallback-query.component";
import { useGetUserByIdQuery } from "lib/api/hooks/use-get-user-query.hook";
import { generateDate } from "lib/utils/date";
import { getNameByUser } from "lib/utils/entities/users/get-username.utilit";
import {
    testIds,
    UserInfoProps,
    USER_DEFAULT_AVATAR,
} from "./user-info.interface";
import {
    StyledContainer,
    StyledContent,
    StyledKey,
    StyledRole,
    StyledRow,
    StyledUserDataBlock,
    StyledUserGeneralInfoBlock,
    StyledUserName,
    StyledValue,
} from "./user-info.styled";
import { NOT_ASSIGNED_SUB_ROLE_LABEL } from "lib/constants/not-assigned-sub-role";

export const UserInfo = ({ userId }: UserInfoProps) => {
    const { t } = useTranslation("users");

    const usersQuery = useGetUserByIdQuery(userId);

    const userRole =
        usersQuery.data?.user?.general.sub_role_title ||
        NOT_ASSIGNED_SUB_ROLE_LABEL;
    const isUserBanned = usersQuery.data?.user?.general?.is_banned;
    const userBannedAt = usersQuery.data?.user?.general?.banned_at;
    const isUserConfirmed = usersQuery.data?.user?.general?.is_confirmed;
    const userCreatedAt = usersQuery.data?.user?.general?.created_at;
    const userLastActiveAt = usersQuery.data?.user?.general?.last_active_at;
    const userEmail = usersQuery.data?.user?.general.email;

    return (
        <FallbackQuery queries={[usersQuery]}>
            <StyledContent data-testid={testIds.userInfoBlock}>
                <StyledContainer>
                    <Avatar size={170} avatarImageUrl={USER_DEFAULT_AVATAR} />
                    <StyledUserGeneralInfoBlock>
                        <StyledUserName>
                            {getNameByUser(usersQuery.data)}
                        </StyledUserName>
                        <StyledRole>{userRole}</StyledRole>
                        <UserStatusLabel
                            isBanned={isUserBanned}
                            isConfirmed={isUserConfirmed}
                        />
                    </StyledUserGeneralInfoBlock>
                    <StyledUserDataBlock>
                        <StyledRow>
                            <StyledKey>{t("label.email", "E-mail")}</StyledKey>
                            <StyledValue>{userEmail}</StyledValue>
                        </StyledRow>
                        {isUserConfirmed && (
                            <>
                                <StyledRow>
                                    <StyledKey>
                                        {t("label.created", "Created")}
                                    </StyledKey>
                                    <StyledValue>
                                        {generateDate(userCreatedAt)}
                                    </StyledValue>
                                </StyledRow>
                                <StyledRow>
                                    <StyledKey>
                                        {t(
                                            "label.lastEntrance",
                                            "Last entrance",
                                        )}
                                    </StyledKey>
                                    <StyledValue>
                                        {generateDate(userLastActiveAt, true)}
                                    </StyledValue>
                                </StyledRow>
                                {isUserBanned && (
                                    <StyledRow>
                                        <StyledKey>
                                            {t("label.blocked", "Blocked")}
                                        </StyledKey>
                                        <StyledValue>
                                            {generateDate(userBannedAt, true)}
                                        </StyledValue>
                                    </StyledRow>
                                )}
                            </>
                        )}
                    </StyledUserDataBlock>
                </StyledContainer>
            </StyledContent>
        </FallbackQuery>
    );
};
