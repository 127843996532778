export enum SortingOrder {
    ASC = "asc",
    DESC = "desc",
}


export interface SortingInfo {
    name: string;
    order: SortingOrder;
}

export interface SortingButtonProps {
    name: string;
    filterBy: (data: SortingInfo) => void;
    info?: string;
    filterInfo: SortingInfo;
    label: string;
}
