import { Select, MultiSelect } from "@mantine/core";
import styled, { css } from "styled-components";

import {
    DropdownGeneralBlockProp,
    DropdownStyledProps,
    MultiDropdownStyledProps,
} from "./dropdown.interface";
import { colors } from "lib/palette";

export const StyledDropdownBlock = styled.div<DropdownGeneralBlockProp>`
    width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "max-content")};
    height: max-content;
    position: relative;
`;

export const StyledDropdown = styled(Select) <DropdownStyledProps>`
    width: 100%;
    border: 1px solid ${({ error }) => (error ? colors.red_1 : colors.black_3)};
    border-radius: 5px;
    background-color: ${({ disabled }) =>
        disabled ? colors.black_4 : colors.white};

    &:hover {
        border: 1px solid
            ${({ error }) => (error ? colors.red_1 : colors.black_2)};
    }

    &:focus {
        border: 1px solid
            ${({ error }) => (error ? colors.red_1 : colors.black_2)};
    }

    ${({ disabled }) =>
        disabled &&
        `
       border: 1px solid ${colors.black_4} !important;
    `};

    .mantine-Input-input.mantine-Select-input {
        border: none;
        background: transparent;
        padding-right: ${({ $showErrorIcon }) =>
            $showErrorIcon ? "54px" : "36px"};

        &[value=""] {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .mantine-Select-rightSection {
        pointer-events: none;
        width: 36px;
    }

    .mantine-Select-rightSection .dropdown-icon-block {
        pointer-events: none;
        transform: rotate(0deg);
    }

    [aria-expanded*="true"] .mantine-Select-rightSection .dropdown-icon-block {
        transform: rotate(180deg);
    }

    .mantine-Select-item {
        white-space: normal;
        word-break: break-all;
        width: 100%;
        max-width: 100%;
    }
`;

export const StyledMultiDropdown = styled(
    MultiSelect,
) <MultiDropdownStyledProps>`
    width: ${({ $isFullWidth }) => ($isFullWidth ? "100%" : "250px")};
    border: 1px solid ${({ error }) => (error ? colors.red_1 : colors.black_3)};
    border-radius: 5px;
    background-color: ${({ disabled }) =>
        disabled ? colors.black_4 : colors.white};

    &:hover {
        border: 1px solid
            ${({ error }) => (error ? colors.red_1 : colors.black_2)};
    }

    &:focus {
        border: 1px solid
            ${({ error }) => (error ? colors.red_1 : colors.black_2)};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            border: 1px solid ${colors.black_4} !important;
        `};

    .mantine-Input-input.mantine-MultiSelect-input {
        border: none;
        background: transparent;
    }

    .mantine-MultiSelect-wrapper {
        .mantine-MultiSelect-rightSection {
            pointer-events: ${({ value }) =>
        !value?.length ? "none" : "auto"};
        }

        &[aria-expanded*="true"] {
            .mantine-MultiSelect-rightSection {
                transform: rotate(180deg);
            }
        }
    }

    .mantine-MultiSelect-dropdown {
        top: 53px !important;
    }

    .mantine-MultiSelect-item {
        white-space: normal;
        word-break: break-all;
        width: 100%;
        max-width: 100%;

        &:hover {
            background-color: ${colors.blue_4};
        }
    }

    .mantine-MultiSelect-values {
        gap: 4px;

        .mantine-MultiSelect-value {
            margin: 0;
            background-color: ${colors.blue_4};
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 10px;
            gap: 10px;
            width: 61px;
            height: 24px;
            border-radius: 4px;

            .mantine-MultiSelect-defaultValueLabel {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
            }

            .mantine-MultiSelect-defaultValueRemove {
                width: 6px;
                height: 6px;
                display: flex;
                min-height: auto;
                background-size: 6px;
                padding: 0;
                margin: 0;
                min-width: 6px;
                justify-content: center;
                align-items: center;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.23964 0.23964C0.55916 -0.07988 1.0772 -0.07988 1.39672 0.23964L3 1.84292L4.60328 0.23964C4.9228 -0.07988 5.44084 -0.07988 5.76036 0.23964C6.07988 0.55916 6.07988 1.0772 5.76036 1.39672L4.15708 3L5.76036 4.60328C6.07988 4.9228 6.07988 5.44084 5.76036 5.76036C5.44084 6.07988 4.9228 6.07988 4.60328 5.76036L3 4.15708L1.39672 5.76036C1.0772 6.07988 0.55916 6.07988 0.23964 5.76036C-0.07988 5.44084 -0.07988 4.9228 0.23964 4.60328L1.84292 3L0.23964 1.39672C-0.07988 1.0772 -0.07988 0.55916 0.23964 0.23964Z' fill='%23D9E3FF'/%3E%3C/svg%3E");
                background-position: center;
                background-repeat: no-repeat;

                &:hover {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='black' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.23964 0.23964C0.55916 -0.07988 1.0772 -0.07988 1.39672 0.23964L3 1.84292L4.60328 0.23964C4.9228 -0.07988 5.44084 -0.07988 5.76036 0.23964C6.07988 0.55916 6.07988 1.0772 5.76036 1.39672L4.15708 3L5.76036 4.60328C6.07988 4.9228 6.07988 5.44084 5.76036 5.76036C5.44084 6.07988 4.9228 6.07988 4.60328 5.76036L3 4.15708L1.39672 5.76036C1.0772 6.07988 0.55916 6.07988 0.23964 5.76036C-0.07988 5.44084 -0.07988 4.9228 0.23964 4.60328L1.84292 3L0.23964 1.39672C-0.07988 1.0772 -0.07988 0.55916 0.23964 0.23964Z' fill='%23546683'/%3E%3C/svg%3E");
                }

                svg {
                    display: none;
                }
            }
        }
    }
`;

export const StyledErrorMessageContainer = styled.p`
    width: max-content;
    margin: 8px 0 0;
    color: ${colors.red_1};
    font-size: 12px;
    font-weight: 400;
`;

export const StyledSelectContainer = styled.div`
    width: 100%;
    height: max-content;
    position: relative;
`;

export const StyledErrorIconBlock = styled.div`
    width: 24px;
    height: 24px;
    position: absolute;
    margin: auto;
    right: 30px;
    top: 0;
    bottom: 0;
`;
