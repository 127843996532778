import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";

export const StyledContent = styled.div<{
    withMobileHeader: boolean;
    overflowY: "auto" | "hidden";
}>`
    width: 100%;
    height: calc(100vh - 60px);
    overflow-y: ${({ overflowY }) => overflowY};
    position: relative;

    @media (max-width: ${`${breakpoints.xl}px`}) {
        height: ${({ withMobileHeader }) =>
            withMobileHeader ? "calc(100vh - 113px)" : "calc(100vh - 56px)"};
    }

    @media (max-width: ${`${breakpoints.sm}px`}) {
        padding-bottom: 20px;
    }
`;
