import { Avatar } from "components/atoms/avatar/avatar.component";
import { UserStatusLabel } from "../../molecules/user-status-label/user-status-label.component";
import { UserCardProps } from "./user-card.interface";
import {
    StyledBlock,
    StyledInfoBlock,
    StyledRole,
    StyledUsername,
} from "./user-card.styled";
import { NOT_ASSIGNED_SUB_ROLE_LABEL } from "lib/constants/not-assigned-sub-role";

const USER_DEFAULT_AVATAR = "/images/user-avatar.png";

export const UserCard = ({
    username,
    roleTitle,
    isBanned,
    isConfirmed,
    avatarImgUrl = USER_DEFAULT_AVATAR,
}: UserCardProps) => {
    return (
        <StyledBlock>
            <Avatar size={36} avatarImageUrl={avatarImgUrl} />
            <StyledInfoBlock>
                <StyledUsername>{username}</StyledUsername>
                <StyledRole>
                    {roleTitle || NOT_ASSIGNED_SUB_ROLE_LABEL}
                </StyledRole>
            </StyledInfoBlock>
            <UserStatusLabel isBanned={isBanned} isConfirmed={isConfirmed} />
        </StyledBlock>
    );
};
