import { ReactNode } from "react";

import { SortingInfo } from "components/molecules/table/components/sorting-button/sorting-button.interface";

export type UserRows = Record<
    string,
    { name: string; render: () => ReactNode }
>[];

export type UsersProps = {
    usersList?: UserRows;
    setSortBy: (data: SortingInfo) => void;
    sortBy: SortingInfo;
};

export const mobileColumns = [
    {
        name: "avatar",
        render: () => "",
    },
    { name: "userInfo", render: () => "" },
    {
        name: "status",
        render: () => "",
    },
    {
        name: "actions",
        render: () => "",
    },
];
