import {
    DeleteUserInfoType,
    UserData,
} from "components/templates/users/users.interface";

export interface UserInfoMenuButtonProps {
    userInfo: UserData;
    setChangeRoleData: (data: UserData) => void;
    setDeleteUserInfo: (data?: DeleteUserInfoType) => void;
    openUserInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
    setUserId: (userId: string) => void;
    setReset2FAModalData: (id: string) => void;
    setUserStatusModalData: React.Dispatch<
        React.SetStateAction<{
            username: string;
            id: string;
            isBaned: boolean;
            isOpen: boolean;
        }>
    >;
}

export const REGISTRATION_LINK_SUCCESS_TOAST_MESSAGE_KEY =
    "toast.successRegistrationLink";
export const REGISTRATION_LINK_ERROR_TOAST_MESSAGE_KEY =
    "toast.errorRegistrationLink";
