export interface CreateNewUserModalProps {
    opened: boolean;
    onClose: () => void;
}

export const testIds = {
    createProviderUserModal: "create-provider-user-modal",
    createProviderUserSuccessToast: "create-provider-user-success-toast",
    createProviderUserErrorToast: "create-provider-user-error-toast",
    createProviderUserModalBody: "create-provider-user-modal-body",
    createProviderUserModalSubmitButton:
        "create-provider-user-modal-submit-button",
    createProviderUserModalCancelButton:
        "create-provider-user-modal-cancel-button",
};

export const CREATE_NEW_USER_SUCCESS_TOAST_MESSAGE_KEY =
    "toast.successCreateNewUser";
export const CREATE_NEW_USER_ERROR_TOAST_MESSAGE_KEY =
    "toast.errorCreateNewUser";
