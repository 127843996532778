import { IFormState } from "lib/types/form";
import { RpcStatus, V1CreateUserResponse } from "lib/api/common/data-contracts";
import { UserType } from "lib/constants/user-type";

export type CreateUserFormProps = {
    onSuccess: (data?: V1CreateUserResponse) => void;
    onError: (error?: RpcStatus) => void;
    setFormState: React.Dispatch<React.SetStateAction<IFormState>>;
    withSubRole?: boolean;
    companyId?: string;
    providerId?: string;
    userType: UserType;
};

export const testIds = {
    createUserFormComponent: "create-user-form-component",
    createUserFormEmailInput: "create-user-form-email-input",
    createUserFormRoleInput: "create-user-form-role-input",
    createUserFormRoleDropdown: "create-user-form-role-dropdown",
    createUserFormFirstNameInput: "create-user-form-first-name-input",
    createUserFormLastNameInput: "create-user-form-last-name-input",
    createUserFormHiddenSubmitButton: "create-user-form-hidden-submit-button",
    mandatory2faCheckbox: "mandatory-2fa-checkbox",
    roleDropdownOption: "role-dropdown-option",
};

export const MANDATORY_2FA_LABEL_KEY =
    "createUserForm.description.mandatory2fa";
export const NOT_UNIQUE_EMAIL_KEY = "createUserForm.description.notUniqueEmail";
export const INVALID_EMAIL_KEY = "createUserForm.description.invalidEmail";
export const INVALID_FIRST_NAME_KEY =
    "createUserForm.description.invalidFirstName";
export const FIRST_NAME_LIMIT_KEY = "createUserForm.description.firstNameLimit";
export const INVALID_LAST_NAME_KEY =
    "createUserForm.description.invalidLastName";
export const LAST_NAME_LIMIT_KEY = "createUserForm.description.lastNameLimit";
export const ROLE_IS_REQUIRED_KEY = "createUserForm.description.roleIsRequired";
export const FORBID_START_END_2_SPACES_KEY =
    "validationError.forbidStartEnd2Spaces";
