import { StyledContent } from "./content.styled";
import { ContentProps, testIds } from "./content.interface";

export const Content = ({
    children,
    withMobileHeader = false,
    className,
    overflowY = "auto",
}: ContentProps) => {
    return (
        <StyledContent
            overflowY={overflowY}
            className={className}
            withMobileHeader={withMobileHeader}
            data-testid={testIds.pageContent}
        >
            {children}
        </StyledContent>
    );
};
