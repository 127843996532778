import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { colors } from "lib/palette";

export const StyledMobileHeader = styled.p<{ centered?: boolean }>`
    display: none;
    width: 100%;
    padding: 15px 20px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: ${colors.black_1};
    text-align: ${({ centered }) => centered ? "center" : "left"};

    @media (max-width: ${`${breakpoints.sm}px`}) {
        display: block;
    }
`;
