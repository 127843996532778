import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";

import { DeleteNotConfirmedUserModalProps } from "./delete-not-confirmed-user-modal.interface";
import { useDeleteNotConfirmedUserMutation } from "lib/api/hooks/use-delete-not-confirmed-user-mutation.hook";
import { WarningModal } from "components/molecules/warning-modal/warning-modal.component";
import { useGetUsersQueryKey } from "lib/api/hooks/use-get-users-query";
import { BUTTONS_DIRECTION } from "components/molecules/warning-modal/warning-modal.constants";
import { errorToast, successToast } from "lib/utils/toast";

export const DeleteNotConfirmedUserModal = ({
    opened,
    onClose,
    userInfo,
}: DeleteNotConfirmedUserModalProps) => {
    const { t } = useTranslation("users");

    const queryClient = useQueryClient();

    const mutation = useDeleteNotConfirmedUserMutation({
        onSuccess: async () => {
            successToast(t("toast.successDeleteUser", "The user was deleted"), {
                className: "toast-user-was-deleted",
            });

            await queryClient.invalidateQueries([useGetUsersQueryKey]);

            onClose();
        },
        onError: () => {
            errorToast(
                t(
                    "toast.errorDeleteUser",
                    "There was an error. The user was not deleted.",
                ),
            );
        },
    });

    return (
        <WarningModal
            opened={opened}
            onClose={onClose}
            onCancel={onClose}
            title={t("title.attention", "Attention")}
            message={t(
                "description.deleteUser",
                "Are you sure you want to delete the user {{username}}?",
                {
                    username: userInfo?.username,
                },
            )}
            onOk={() => userInfo && mutation.mutate(userInfo.id)}
            okButtonIsLoading={mutation.isLoading}
            okButtonVariant="outlined"
            okButtonText={t("button.delete", "Delete")}
            cancelButtonText={t("button.cancel", "Cancel")}
            cancelButtonVariant="primary"
            buttonsDirection={BUTTONS_DIRECTION.rowReverse}
            okButtonTestId="delete-button-test-id"
            cancelButtonTestId="cancel-button-test-id"
        />
    );
};
