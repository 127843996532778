import styled from "styled-components";

import { breakpoints } from "lib/breakpoints";
import { colors, shadow } from "lib/palette";
import { AdaptiveModal } from "components/molecules/modal/components/adaptive-modal/adaptive-modal.component";
import { Stepper } from "@mantine/core";

export const StyledModal = styled(AdaptiveModal)`
    .mantine-Modal-modal {
        max-width: 560px;
        width: 100%;

        @media (max-width: ${`${breakpoints.sm}px`}) {
            max-width: 100%;
            padding-top: 56px;
        }
    }

    .mantine-Modal-body {
        display: flex;
        flex-direction: column;

        @media (max-width: ${`${breakpoints.sm}px`}) {
            overflow-y: auto;
            margin-bottom: 60px;
            height: calc(100% - 60px);
        }
    }
`;

export const StyledStepContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const StyledMobileHeader = styled.div`
    padding: 20px 0 15px;
    display: none;

    @media (max-width: ${`${breakpoints.sm}px`}) {
        display: flex;
        justify-content: center;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 10px;
            left: 0;
            top: 56px;
            background: ${shadow.top};
        }
    }
`;

export const StyledTitle = styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${colors.black_6};
    text-align: center;
`;

export const StyledToastError = styled.div`
    a {
        color: ${colors.white};
    }
`;

export const StyledStepTitle = styled.h2`
    width: 100%;
    max-width: 400px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${colors.black_1};
    margin-bottom: 20px;
`;

export const StyledFormBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 50px;

    @media (max-width: ${`${breakpoints.sm}px`}) {
        padding: 10px 0;
    }
`;

export const StyledStep = styled(Stepper.Step)`
    .mantine-Stepper-stepLabel {
        max-width: 125px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
